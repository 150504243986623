<template>
  <div class="tabs-mf bg-white tab-payment">
    <div
      v-if="!hasStepGuide"
      class="form-action"
    >

      <!-- filter desktop -->
      <div class="filter-desktop balance-allocation px-1">
        <!-- filter 1 -->
        <b-row class="my-2 input-control">
          <b-col
            md="4"
            lg="3"
            offset-md="3"
            offset-lg="5"
            class="mt-1 mt-md-0"
          >
            <div class="status-input input-height">
              <v-select
                v-model="statusSelected"
                class="input-height custom-select-input"
                label="name"
                :options="statusOptions"
                :clearable="false"
                @input="handleStatusChange"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="20"
                    />
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
          <b-col
            lg="4"
            md="5"
            class="input-height pl-md-0 mt-1 mt-md-0"
          >
            <calendar
              v-model="rangeDate"
              :placeholder="placeholder"
              @input="handleDateChange"
            />
          </b-col>
        </b-row>
      </div>
      <!-- /filter desktop -->
    </div>

    <!-- table -->
    <vue-good-table
      class="vgt-no-border table-history-refund"
      mode="remote"
      :columns="columns"
      :rows="listRequestRefund"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: totalHistoryRefund > 0,
      }"
      :sort-options="{
        enabled: totalHistoryRefund > 0,
      }"
      :is-loading="loading"
      @on-sort-change="onSortChange"
    >
      <template
        slot-scope="props"
        slot="table-row"
      >

        <!-- Column: Business Account -->
        <div
          v-if="props.column.field === 'createdAt'"
          class="d-flex align-items-center"
        >
          <span>{{ createdDate(props.row.createdAt ) }}</span>
        </div>

        <div v-else-if="props.column.field === 'amount'">
          <span>${{ requestedAmount(props.row) }}</span>
        </div>

        <div
          v-else-if="props.column.field === 'paymentMethod'"
          class="d-flex align-items-center"
        >
          <p class="payment-method-content">
            {{ receivingMethod(props.row) }}
          </p>
          <div
            v-if="receivingMethod(props.row) !== 'Credit card'"
            class="d-flex"
          >
            <feather-icon
              :id="`popover-target-${idPopover(props.row)}`"
              class="cursor-pointer"
              icon="AlertCircleIcon"
              size="16"
              @mousemove="additionalInfoRequest(props.row)"
            />
            <b-popover
              :target="`popover-target-${idPopover(props.row)}`"
              triggers="hover"
              placement="right"
            >
              <div>
                <div
                  v-if="additionalInfo.email"
                  class="addition-info-container"
                >
                  <p class="title-addition">
                    ・ {{ receivingMethod(props.row) }} Email
                  </p>
                  <p class="content-addition">
                    {{ additionalInfo.email }}
                  </p>
                </div>
                <div
                  v-if="additionalInfo.wiseAccountHolder"
                  class="addition-info-container"
                >
                  <p class="title-addition">
                    ・ {{ $t('payment.wiseTransfer.titleAccountHolder', { paymentMethod: 'Wise'}) }}
                  </p>
                  <p class="content-addition">
                    {{ additionalInfo.wiseAccountHolder }}
                  </p>
                </div>
                <div
                  v-if="additionalInfo.wiseRountingNumber"
                  class="addition-info-container"
                >
                  <p class="title-addition">
                    ・ {{ $t('payment.wiseTransfer.titleRoutingNumber', { paymentMethod: 'Wise'}) }}
                  </p>
                  <p class="content-addition">
                    {{ additionalInfo.wiseRountingNumber }}
                  </p>
                </div>
                <div
                  v-if="additionalInfo.wiseAccountNumber"
                  class="addition-info-container"
                >
                  <p class="title-addition">
                    ・ {{ $t('payment.wiseTransfer.textBankAccountNumber', { paymentMethod: 'Wise'}) }}
                  </p>
                  <p class="content-addition">
                    {{ additionalInfo.wiseAccountNumber }}
                  </p>
                </div>
                <div
                  v-if="additionalInfo.wiseAccountType"
                  class="addition-info-container"
                >
                  <p class="title-addition">
                    ・ {{ $t('payment.wiseTransfer.titleAccountType', { paymentMethod: 'Wise'}) }}
                  </p>
                  <p class="content-addition">
                    {{ additionalInfo.wiseAccountType }}
                  </p>
                </div>
                <div
                  v-if="additionalInfo.wiseAddress"
                  class="addition-info-container"
                >
                  <p class="title-addition">
                    ・ {{ $t('payment.wiseTransfer.textAddress', { paymentMethod: 'Wise'}) }}
                  </p>
                  <p class="content-addition">
                    {{ additionalInfo.wiseAddress }}
                  </p>
                </div>
                <div
                  v-if="additionalInfo.usdtWalletAddress"
                  class="addition-info-container"
                >
                  <p class="title-addition">
                    ・ {{ $t('payment.USDT.labelWalletAddress', {value: 'USDT' }) }}
                  </p>
                  <p class="content-addition">
                    {{ additionalInfo.usdtWalletAddress }}
                  </p>
                </div>
                <div
                  v-if="additionalInfo.usdtNetwork"
                  class="addition-info-container"
                >
                  <p class="title-addition">
                    ・ {{ $t('payment.autoRefundFeature.labelUSDTNetwork') }}
                  </p>
                  <p class="content-addition">
                    {{ additionalInfo.usdtNetwork }}
                  </p>
                </div>
              </div>
            </b-popover>
          </div>
        </div>

        <div v-else-if="props.column.field === 'refundReason'">
          <span class="text-capitalize">{{ refundReason(props.row) }}</span>
        </div>

        <span
          v-else-if="props.column.field === 'status'"
          class="text-nowrap text-capitalize"
        >
          <b-badge
            :variant="statusVariant(props.row)"
            class="font-14"
          >
            {{ statusTxt(props.row) }}
          </b-badge>
        </span>

        <div v-else-if="props.column.field === 'rejectReason'">
          <div
            v-if="rejectReason(props.row)"
            class="column-reason-reject"
          >
            <div
              :class="isShowFullReason && (idRejectReason === props.row._id) ? 'full-content-reason' : 'shot-content-reason'"
              class="content-reject-reason"
              v-html="rejectReason(props.row)"
            />
            <small v-if="isShowBtnShowMore(rejectReason(props.row))">
              <strong>
                <span
                  class="show-more"
                  @click="handleShowReason(props.row)"
                >{{ isShowFullReason && (idRejectReason === props.row._id) ? $t('payment.autoRefundFeature.showLess') : $t('payment.autoRefundFeature.showMore') }}</span>
              </strong>
            </small>
          </div>
          <span v-else>
            N/A
          </span>
        </div>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalHistoryRefund > 0"
        slot="pagination-bottom"
      >
        <div
          class="d-flex flex-wrap mt-1 footer-tab"
          :class="[
            { 'justify-content-center': $device.mobile },
            { 'justify-content-between': !$device.mobile },
          ]"
        >
          <!-- page length -->
          <div class="d-flex align-items-center mb-0">
            <span
              class="font-14 font-medium"
            >{{ totalHistoryRefund }} {{ $t('payment.balanceAllocationTable.textResult') }}</span>
          </div>
          <div>
            <b-pagination
              :total-rows="totalHistoryRefund"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 mt-1 mt-md-0 pagination-table"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>

      <!-- no account image -->
      <div
        slot="emptystate"
        class="text-center"
      >
        <no-balance-allocation class="my-5" />
      </div>
      <!-- /no account image -->
    </vue-good-table>
    <!-- /table -->

  </div>
</template>
<script>
/* eslint-disable no-underscore-dangle */
import {
  BPagination,
  BSpinner,
  VBTooltip,
  BRow,
  BCol,
  VBModal,
  BBadge,
  BPopover,
} from 'bootstrap-vue'
// libs & cons
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { createNamespacedHelpers } from 'vuex'
import Ripple from 'vue-ripple-directive'
import Calendar from '@/components/Calendar.vue'
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from '@/constants'
import envMixin from '@/mixins/envMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import NoBalanceAllocation from './NoBalanceAllocation.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('payment')

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },

  components: {
    NoBalanceAllocation,
    BPagination,
    BRow,
    BCol,
    BSpinner,
    BBadge,
    // components
    VueGoodTable,
    vSelect,
    Calendar,
    BPopover,
  },

  mixins: [envMixin, stepGuideMixin],

  data() {
    return {
      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // per page
      pageLength: 10,
      pages: ['5', '10', '20'],

      action: '',
      rangeDate: {
        startDate: null,
        endDate: null,
      },
      placeholder: this.$t('payment.AdsAccountHistoryTable.placeholderSelectDate'),

      // status filter
      statusSelected: {
        name: this.$t('payment.balanceTransactionsTable.textAllStatus'),
        value: '',
      },
      statusOptions: [
        {
          name: this.$t('payment.balanceTransactionsTable.textAllStatus'),
          value: '',
        },
        {
          name: this.$t('guidePopUp.guideTopUpWise.textDone'),
          value: TRANSACTION_STATUS.DONE,
        },
        {
          name: this.$t('payment.textPending'),
          value: TRANSACTION_STATUS.PENDING,
        },
        {
          name: this.$t('billingPage.statusRejected'),
          value: TRANSACTION_STATUS.REJECTED,
        },
      ],

      columns: [
        {
          label: this.$t('payment.autoRefundFeature.labelDate'),
          field: 'createdAt',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-amount',
        },
        {
          label: this.$t('payment.autoRefundFeature.txtRequestedAmount'),
          field: 'amount',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-amount',
        },
        {
          label: this.$t('payment.autoRefundFeature.txtReceivingMethod'),
          field: 'paymentMethod',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-receiving-method',
        },
        {
          label: this.$t('payment.autoRefundFeature.txtRefundReason'),
          field: 'refundReason',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-receiving-method',
        },
        {
          label: this.$t('balance.textStatus'),
          field: 'status',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-amount',
        },
        {
          label: this.$t('partnerView.titleRejectReason'),
          field: 'rejectReason',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-reject-reason',
        },
      ],

      additionalInfo: {},
      isShowFullReason: false,
      idRejectReason: null,
    }
  },

  computed: {
    ...mapGetters(['loading', 'listRefundUserHistory']),

    isShowBtnShowMore() {
      return content => content.length > 150
    },

    idPopover() {
      return val => val?._id
    },

    statusVariant() {
      const statusColor = {
        [TRANSACTION_STATUS.DONE]: 'light-success',
        [TRANSACTION_STATUS.REJECTED]: 'light-danger',
        [TRANSACTION_STATUS.PENDING]: 'light-warning',
      }

      return data => statusColor[data.status]
    },

    statusTxt() {
      const statusTxt = {
        [TRANSACTION_STATUS.DONE]: this.$t('guidePopUp.guideTopUpWise.textDone'),
        [TRANSACTION_STATUS.REJECTED]: this.$t('billingPage.statusRejected'),
        [TRANSACTION_STATUS.PENDING]: this.$t('billingPage.statusPending'),
      }

      return data => statusTxt[data.status]
    },

    receivingMethod() {
      return data => {
        switch (data.paymentMethod) {
          case 'paypal':
            return 'PayPal'
          case 'creditCard':
            return 'Credit card'
          case 'transferWise':
            return 'Wise'
          case 'payoneer':
            return 'Payoneer'
          case 'airWallex':
            return 'Airwallex'
          case 'tazapay':
            return 'Tazapay'
          default:
            return data.paymentMethod
        }
      }
    },

    refundReason() {
      return data => {
        switch (data?.payload?.reasonType) {
          case 'permanent_suspended':
            return this.$t('payment.autoRefundFeature.txtPermanentServiceDiscontinuation')
          case 'temporary_suspended':
            return this.$t('payment.autoRefundFeature.txtTemporarilyStopUsingService')
          case 'other':
            return this.$t('payment.autoRefundFeature.txtOther')
          default:
            return data?.payload?.reasonType
        }
      }
    },

    rejectReason() {
      return data => {
        const reasonContent = data?.payload?.amReviewedRefundPayload?.rejectedReason || data?.payload?.fmReviewedRefundPayload?.rejectedReason
        if (reasonContent?.length > 150 && !this.isShowFullReason) {
          this.isShowFullReason = false
          this.idRejectReason = data._id
        }

        return data?.payload?.amReviewedRefundPayload?.rejectedReason || data?.payload?.fmReviewedRefundPayload?.rejectedReason
      }
    },

    requestedAmount() {
      return data => data?.amount
    },

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    listRequestRefund() {
      return this.listRefundUserHistory.content
    },

    totalHistoryRefund() {
      return this.listRefundUserHistory.paging?.total
    },

    rangeDates() {
      return {
        from: this.rangeDate.startDate,
        to: this.rangeDate.endDate || this.rangeDate.startDate,
      }
    },
  },

  created() {
    this.fetchRefundHistory()
  },

  methods: {
    ...mapActions(['getHistory']),

    handleShowReason(data) {
      if ((this.idRejectReason === data._id) || (this.idRejectReason !== data._id && !this.isShowFullReason)) {
        this.isShowFullReason = !this.isShowFullReason
        this.idRejectReason = data._id
      } else {
        this.idRejectReason = data._id
      }
    },

    additionalInfoRequest(data) {
      this.additionalInfo = data?.payload?.additionalInfo || null
    },

    async handleDateChange(selectedDates) {
      this.rangeDate = selectedDates
      await this.fetchRefundHistory()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchRefundHistory()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchRefundHistory()
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchRefundHistory()
    },

    async fetchRefundHistory() {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        types: [TRANSACTION_TYPE.REFUND_USER_BALANCE],
        ...(this.sortTerm.field && { sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${this.sortTerm.field}` }),
        ...(this.searchTerm && { keyword: this.searchTerm }),
        ...(this.statusSelected.value && { status: this.statusSelected.value }),
        ...((this.rangeDate.startDate || this.rangeDate.endDate) && this.rangeDates),
      }

      await this.getHistory({
        type: 'refund_user_balance',
        params,
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-good-table.scss';

  .table-history-refund {
    .td-amount {
      min-width: 150px;
    }

    .td-receiving-method {
      min-width: 200px;
    }

    .td-reject-reason {
      min-width: 300px;
      width: max-content;
    }
  }

  .input-control{
    .vs__dropdown-toggle{
      height: 40px;
    }
  }

  .tabs-mf .nav-tabs{
    padding: 16px 24px 0;

    .nav-link{
      padding-top: 0;
      padding-bottom: 14px;

      @media(max-width: 767px) {
        padding-bottom: 0px;
        margin-bottom: 14px;
        margin-right: 20px;
      }
    }
  }

  .status-input{
    .vs__selected{
      margin-top: 0 !important;
    }

    .vs__open-indicator{
      margin-top: 0 !important;
    }
  }

  .custom-date-input{
    .vs__dropdown-toggle{
      padding: 0 !important;
    }

    .vs__open-indicator{
      margin: 0 !important;
    }
  }

  .custom-select-input {
    .vs__dropdown-toggle {
      align-items: center;
    }
  }
</style>

<style lang="scss" scoped>
.shot-content-reason {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.show-more, .show-less {
  cursor: pointer;
  text-transform: uppercase;
  color: grey;
}

.tab-payment {
  .tab-title{
    font-size: 15px;
  }
}

.addition-info-container:not(:last-child) {
  margin-bottom: 12px;
}

.title-addition {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  line-height: 20px;
}

.content-addition {
  font-size: 12px;
  margin: 0 0 0 1rem;
}

.btn-disabled {
  display: none;
}

.balance-allocation {
  padding: 0 24px;
}

.input-height {
  height: 40px;
}

.footer-tab {
  padding: 0 1rem 1rem;
}

.column-reason-reject {
  max-width: 450px;

  .content-reject-reason ::v-deep p:last-child {
    margin: 0;
  }
}

.btn-add-fund, .btn-withdraw{
  display: flex;
  align-items: center;
  border-radius: 8px !important;
  width: max-content;
}

.btn-withdraw {
  margin-top: 8px;
}

.btn-disabled {
  color: rgba(22, 33, 62, 0.7);
  border: 1px solid rgba(22, 33, 62, 0.7) !important;
}

.payment-method-content {
  text-transform: capitalize;
  margin: 0 6px 0 0;
}
</style>
