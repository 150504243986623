<template>
  <div>
    <b-sidebar
      v-model="show"
      id="sidebar-form-request-refund"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @hidden="onHidden"
    >
      <template>
        <!-- Header -->
        <div class="header-popup">
          <h3 class="title-popup">
            {{ $t('payment.autoRefundFeature.titleRefundRequestForm') }}
          </h3>

          <!-- <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="onHidden"
            />
          </div> -->
          <p class="description-form-request-refund">
            {{ $t('payment.autoRefundFeature.subPopupAutoRefund') }}
          </p>
        </div>

        <!-- Body -->
        <validation-observer
          ref="refFormObserver"
          v-slot="{ invalid }"
        >
          <!-- Form -->
          <b-form
            class="popup-auto-refund-form"
            @reset.prevent="resetForm"
          >

            <b-form-group class="group-input">
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="requestAmount"
                >
                  {{ $t('payment.autoRefundFeature.txtRequestedAmount') }} <span class="text-danger">*</span>
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="request-amount-input"
                :name="$t('payment.autoRefundFeature.txtRequestedAmount')"
                :rules="{
                  required,
                  greaterThanZero,
                  ...( receivingMethod && receivingMethod.value === 'payoneer' && {minAmount: { min: minTopUp }}),
                }"
              >
                <b-input-group
                  prepend="$"
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    v-model="requestAmount"
                    id="request-amount"
                    data-testid="request-amount"
                    class="input-height"
                    :name="$t('payment.autoRefundFeature.txtRequestedAmount')"
                    :placeholder="$t('payment.autoRefundFeature.placeholderRequestedAmount')"
                    :state="errors.length > 0 ? false : null"
                    @keyup="handleChangeRequestAmount"
                    @keydown="numberDecimal"
                  />
                  <b-input-group-append is-text>
                    <span
                      class="btn-max-amount"
                      @click="handleMaxAmount"
                    >
                      {{ $t('payment.autoRefundFeature.txtMax') }}
                    </span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="group-input">
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="refundReason"
                >
                  {{ $t('payment.autoRefundFeature.txtRefundReason') }} <span class="text-danger">*</span>
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="refund-reason-input"
                :name="$t('payment.autoRefundFeature.txtRefundReason')"
                rules="required"
              >
                <v-select
                  v-model="refundReason"
                  input-id="refund-reason"
                  data-testid="refund-reason"
                  class="input-height"
                  :clearable="false"
                  :name="$t('payment.autoRefundFeature.txtRefundReason')"
                  :placeholder="$t('payment.autoRefundFeature.txtPlaceholderRefundReason')"
                  :options="refundReasonOptions"
                  :state="errors.length > 0 ? false : null"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="13"
                      />
                    </span>
                  </template>
                </v-select>
                <div
                  v-if="refundReason && refundReason.value === 'permanent_suspended'"
                  class="text-warning d-flex align-items-center"
                >
                  <div class="ic-warning">
                    <feather-icon
                      icon="AlertTriangleIcon"
                      size="16"
                    />
                  </div>
                  <p class="font-12 m-0 content-warning">
                    {{ $t('payment.autoRefundFeature.txtWarningPermanentServiceDiscontinuation') }}
                  </p>
                </div>
                <div
                  v-else-if="refundReason && (refundReason.value === 'temporary_suspended' || refundReason.value === 'other')"
                  class="text-warning d-flex align-items-center"
                >
                  <div class="ic-warning">
                    <feather-icon
                      icon="AlertTriangleIcon"
                      size="16"
                    />
                  </div>
                  <p class="font-12 m-0 content-warning">
                    {{ $t('payment.autoRefundFeature.noteReasonRefundIsTemporary') }}
                  </p>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Description -->
            <b-form-group class="group-input">
              <validation-provider
                #default="{ errors }"
                :name="$t('payment.autoRefundFeature.nameDetailReason')"
                rules="required"
              >
                <div class="d-flex justify-content-between">
                  <label class="form-label">
                    {{ $t('payment.autoRefundFeature.nameDetailReason') }} <span class="text-danger">*</span>
                  </label>
                </div>
                <b-form-textarea
                  v-model.trim="detailReason"
                  id="textarea-default"
                  :placeholder="$t('payment.autoRefundFeature.placeholderDetailReason')"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="group-input">
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="ReceivingMethod"
                >
                  {{ $t('payment.autoRefundFeature.txtReceivingMethod') }} <span class="text-danger">*</span>
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="receiving-method-input"
                :name="$t('payment.autoRefundFeature.txtReceivingMethod')"
                rules="required"
              >
                <v-select
                  v-model="receivingMethod"
                  input-id="receiving-method"
                  data-testid="receiving-method"
                  class="input-height"
                  :clearable="false"
                  :name="$t('payment.autoRefundFeature.txtReceivingMethod')"
                  :placeholder="$t('payment.autoRefundFeature.txtPlaceholderReceivingMethod')"
                  :options="receivingMethodOptions"
                  :state="errors.length > 0 ? false : null"
                  @input="handleSelectReceivingMethod"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="13"
                      />
                    </span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- method mail -->
            <b-form-group
              v-if="receivingMethodRequest"
              class="group-input"
            >
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="email"
                >
                  {{ methodSelected }} email <span class="text-danger">*</span>
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="method-email-input"
                name="email"
                :rules="receivingMethodRequest ? 'required|email' : null"
              >
                <b-form-input
                  v-model.trim="emailPaymentMethod"
                  id="method-email"
                  data-testid="method-email"
                  name="email"
                  class="input-height"
                  :placeholder="$t('payment.autoRefundFeature.placeholderMethodEmail', {methodSelected: methodSelected})"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- End method mail -->

            <!-- USDT Wallet address -->
            <b-form-group
              v-if="receivingMethod && receivingMethod.value === 'USDT'"
              class="group-input"
            >
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="ReceivingMethod"
                >
                  {{ $t('payment.USDT.labelWalletAddress', { value: 'USDT' }) }} <span class="text-danger">*</span>
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="wallet-address-input"
                :name="$t('payment.USDT.labelWalletAddress', { value: 'USDT' })"
                :rules="receivingMethod && receivingMethod.value === 'USDT' ? 'required' : null"
              >
                <b-form-input
                  v-model.trim="USDTMethod.walletAddress"
                  id="wallet-address"
                  data-testid="wallet-address"
                  :name="$t('payment.USDT.labelWalletAddress', { value: 'USDT' })"
                  class="input-height"
                  :placeholder="$t('payment.autoRefundFeature.placeholderWalletAddress')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- End USDT Wallet address -->

            <!-- USDT network -->
            <b-form-group
              v-if="receivingMethod && receivingMethod.value === 'USDT'"
              class="group-input"
            >
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="ReceivingMethod"
                >
                  {{ $t('payment.autoRefundFeature.labelUSDTNetwork') }} <span class="text-danger">*</span>
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="usdt-network-input"
                :name="$t('payment.autoRefundFeature.labelUSDTNetwork')"
                :rules="receivingMethod && receivingMethod.value === 'USDT' ? 'required' : null"
              >
                <v-select
                  v-model="USDTMethod.USDTNetwork"
                  input-id="receiving-method"
                  data-testid="receiving-method"
                  class="input-height"
                  :placeholder="$t('payment.autoRefundFeature.placeholderUSDTNetwork')"
                  :clearable="false"
                  :name="$t('payment.autoRefundFeature.labelUSDTNetwork')"
                  :options="usdtNetworkOptions"
                  :state="errors.length > 0 ? false : null"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="13"
                      />
                    </span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- End USDT network -->

            <!-- Wise Method -->
            <b-form-group
              v-if="receivingMethod && receivingMethod.value === 'transferWise'"
              class="group-input"
            >
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="ReceivingMethod"
                >
                  Wise email <span class="text-danger">*</span>
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="receiving-method-input"
                name="Wise email"
                :rules="receivingMethod && receivingMethod.value === 'transferWise' ? 'required|email' : null"
              >
                <b-form-input
                  v-model.trim="wiseMethod.wiseEmail"
                  id="request-amount"
                  data-testid="request-amount"
                  name="Wise email"
                  class="input-height"
                  :placeholder="$t('payment.autoRefundFeature.placeholderWiseEmail')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="receivingMethod && receivingMethod.value === 'transferWise'"
              class="group-input"
            >
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="ReceivingMethod"
                >
                  {{ $t('payment.wiseTransfer.titleAccountHolder', { paymentMethod: 'Wise'}) }} <span class="text-danger">*</span>
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="wise-email-input"
                :name="$t('payment.wiseTransfer.titleAccountHolder', { paymentMethod: 'Wise'})"
                :rules="receivingMethod && receivingMethod.value === 'transferWise' ? 'required' : null"
              >
                <b-form-input
                  v-model.trim="wiseMethod.accountHolder"
                  id="wise-email"
                  data-testid="wise-email"
                  :name="$t('payment.wiseTransfer.titleAccountHolder', { paymentMethod: 'Wise'})"
                  class="input-height"
                  :placeholder="$t('payment.autoRefundFeature.placeholderAccountHolder')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="receivingMethod && receivingMethod.value === 'transferWise'"
              class="group-input"
            >
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="ReceivingMethod"
                >
                  {{ $t('payment.wiseTransfer.titleRoutingNumber', {paymentMethod: 'Wise'}) }} <span class="text-danger">*</span>
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="routing-number-input"
                :name="$t('payment.wiseTransfer.titleRoutingNumber', {paymentMethod: 'Wise'})"
                :rules="receivingMethod && receivingMethod.value === 'transferWise' ? 'required' : null"
              >
                <b-form-input
                  v-model.trim="wiseMethod.routingNumber"
                  id="routing-number"
                  data-testid="routing-number"
                  :name="$t('payment.wiseTransfer.titleRoutingNumber', {paymentMethod: 'Wise'})"
                  class="input-height"
                  :placeholder="$t('payment.autoRefundFeature.placeholderRoutingNumber')"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="receivingMethod && receivingMethod.value === 'transferWise'"
              class="group-input"
            >
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="ReceivingMethod"
                >
                  {{ $t('payment.wiseTransfer.textBankAccountNumber', {paymentMethod: 'Wise'}) }} <span class="text-danger">*</span>
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="account-number-input"
                :name="$t('payment.wiseTransfer.textBankAccountNumber', {paymentMethod: 'Wise'})"
                :rules="receivingMethod && receivingMethod.value === 'transferWise' ? 'required' : null"
              >
                <b-form-input
                  v-model.trim="wiseMethod.accountNumber"
                  id="account-number"
                  data-testid="account-number"
                  :name="$t('payment.wiseTransfer.textBankAccountNumber', {paymentMethod: 'Wise'})"
                  class="input-height"
                  :placeholder="$t('payment.autoRefundFeature.placeholderAccountNumber')"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="receivingMethod && receivingMethod.value === 'transferWise'"
              class="group-input"
            >
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="ReceivingMethod"
                >
                  {{ $t('payment.wiseTransfer.titleAccountType', {paymentMethod: 'Wise'}) }} <span class="text-danger">*</span>
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="account-type-input"
                :name="$t('payment.wiseTransfer.titleAccountType')"
                :rules="receivingMethod && receivingMethod.value === 'transferWise' ? 'required' : null"
              >
                <v-select
                  v-model="wiseMethod.accountType"
                  input-id="account-type"
                  data-testid="account-type"
                  class="input-height"
                  :placeholder="$t('payment.autoRefundFeature.placeholderAccountType')"
                  :clearable="false"
                  :name="$t('payment.wiseTransfer.titleAccountType')"
                  :options="accountTypeOptions"
                  :state="errors.length > 0 ? false : null"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="13"
                      />
                    </span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="receivingMethod && receivingMethod.value === 'transferWise'"
              class="group-input"
            >
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="ReceivingMethod"
                >
                  {{ $t('payment.wiseTransfer.textAddress', { paymentMethod: 'Wise' }) }} <span class="text-danger">*</span>
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="address-input"
                :name=" $t('referral.tabPartnerProgram.textAddress')"
                :rules="receivingMethod && receivingMethod.value === 'transferWise' ? 'required' : null"
              >
                <b-form-input
                  v-model.trim="wiseMethod.address"
                  id="address"
                  data-testid="address"
                  :name=" $t('referral.tabPartnerProgram.textAddress')"
                  class="input-height"
                  :placeholder="$t('payment.autoRefundFeature.placeholderAddress')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- End Wise Method -->
          </b-form>

          <div class="footer-popup">
            <div class="checkbox">
              <b-form-checkbox
                v-model="selected"
                class="custom-control-primary"
                @click="!selected"
              >
                {{ $t('register.textAcceptVerify') }}
                <a
                  target="_blank"
                  :href="termsOfService"
                >{{ $t('register.textTermsOfService') }}</a>
              </b-form-checkbox>
            </div>

            <!-- Form Actions -->
            <div
              class="d-flex justify-content-center mt-2"
            >
              <btn-loading
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant-convert="btn-submit-outline"
                class="mr-1"
                pill
                :loading="loading"
                @click="onHidden"
              >
                {{ $t('common.btnCancel') }}
              </btn-loading>
              <btn-loading
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant-convert="btn-submit"
                :loading="loading"
                :disabled="invalid || !selected"
                @click="handleActionNext"
              >
                {{ $t('payment.autoRefundFeature.btnNext') }}
              </btn-loading>
            </div>
          </div>
        </validation-observer>

      </template>
    </b-sidebar>

    <modal-warning-amount-ads-account
      ref="modal-warning-amount-ads-account"
      @submitInfoRequestPayment="submitInfoRequestPayment"
      @onHidden="onHidden"
    />
  </div>
</template>

<script>
/* eslint-disable no-undef */

import Ripple from 'vue-ripple-directive'
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BInputGroupAppend,
  BFormTextarea,
} from 'bootstrap-vue'
import {
  PAYMENT_METHOD,
} from '@/constants'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  required, email, greaterThanZero, minAmount,
} from '@validations'
import { createNamespacedHelpers } from 'vuex'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import validateMixin from '@/mixins/validateMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import _isEmpty from 'lodash/isEmpty'
import ModalWarningAmountAdsAccount from './ModalWarningAmountAdsAccount.vue'
// import i18n from '@/libs/i18n'

const { mapGetters } = createNamespacedHelpers('auth')
const { mapGetters: mapGettersAdsAccount } = createNamespacedHelpers('adsAccount')

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BInputGroupAppend,
    vSelect,
    BtnLoading,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ModalWarningAmountAdsAccount,
  },

  directives: {
    Ripple,
  },

  mixins: [validateMixin, numberFormatMixin],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data() {
    return {
      requestAmount: null,
      refundReason: null,
      receivingMethod: null,
      emailPaymentMethod: '',
      USDTMethod: {
        walletAddress: '',
        USDTNetwork: null,
      },
      usdtNetworkOptions: [
        {
          label: 'BNB Smart Chain (BEP20)',
          value: 'BNB Smart Chain (BEP20)',
        },
        {
          label: 'Ethereum (ERC20)',
          value: 'Ethereum (ERC20)',
        },
        {
          label: 'Tron (TRC20)',
          value: 'Tron (TRC20)',
        },
        {
          label: 'Solana',
          value: 'Solana',
        },
        {
          label: 'NEAR Protocol',
          value: 'NEAR Protocol',
        },
        {
          label: 'EOS',
          value: 'EOS',
        },
        {
          label: 'Tezos',
          value: 'Tezos',
        },
        {
          label: 'Asset Hub (Polkadot)',
          value: 'Asset Hub (Polkadot)',
        },
        {
          label: 'opBNB',
          value: 'opBNB',
        },
        {
          label: 'CELO',
          value: 'CELO',
        },
        {
          label: 'KAVAEVM',
          value: 'KAVAEVM',
        },
        {
          label: 'Polygon',
          value: 'Polygon',
        },
        {
          label: 'AVAX C-Chain',
          value: 'AVAX C-Chain',
        },
        {
          label: 'Arbitrum One',
          value: 'Arbitrum One',
        },
      ],
      wiseMethod: {
        wiseEmail: '',
        accountHolder: '',
        routingNumber: '',
        accountNumber: '',
        accountType: null,
        address: '',
      },
      detailReason: '',
      show: false,
      selected: false,
      refundReasonOptions: [
        {
          label: this.$t('payment.autoRefundFeature.txtPermanentServiceDiscontinuation'),
          value: 'permanent_suspended',
        },
        {
          label: this.$t('payment.autoRefundFeature.txtTemporarilyStopUsingService'),
          value: 'temporary_suspended',
        },
        {
          label: this.$t('home.adreachSurveyModal.other'),
          value: 'other',
        },
      ],
      accountTypeOptions: [
        {
          label: 'Checking',
          value: 'checking',
        },
        {
          label: 'Savings',
          value: 'savings',
        },
      ],
      receivingMethodOptions: [
        {
          label: 'Credit card',
          value: PAYMENT_METHOD.CREDITCARD,
        },
        {
          label: 'PayPal',
          value: PAYMENT_METHOD.PAYPAL,
        },
        {
          label: 'Payoneer',
          value: PAYMENT_METHOD.PAYONEER,
        },
        {
          label: 'USDT',
          value: PAYMENT_METHOD.USDT,
        },
        {
          label: 'Airwallex',
          value: 'airWallex',
        },
        {
          label: 'Wise',
          value: PAYMENT_METHOD.TRANSFERWISE,
        },
        {
          label: 'Tazapay',
          value: PAYMENT_METHOD.TAZAPAY,
        },
      ],
      termsOfService: 'https://docs.ecomdymedia.com/ecomdy-platform/refund',
      infoRequestRefund: {},
      minTopUp: 50,
      required,
      email,
      greaterThanZero,
      minAmount,
    }
  },

  computed: {
    ...mapGetters(['user', 'loading']),
    ...mapGettersAdsAccount(['listAccount']),

    receivingMethodRequest() {
      return this.receivingMethod?.value === PAYMENT_METHOD.PAYPAL || this.receivingMethod?.value === PAYMENT_METHOD.PAYONEER
      || this.receivingMethod?.value === 'airWallex' || this.receivingMethod?.value === PAYMENT_METHOD.TAZAPAY
    },

    methodSelected() {
      return this.receivingMethod?.label
    },
  },

  watch: {
    showSideBar(show) {
      this.$refs.refFormObserver.reset()
      this.resetForm()
      this.show = show
    },
  },

  methods: {
    resetForm() {
      this.requestAmount = null
      this.refundReason = null
      this.receivingMethod = null
      this.detailReason = ''
      this.selected = false
      this.emailPaymentMethod = ''
      this.USDTMethod = {
        walletAddress: '',
        USDTNetwork: '',
      }
      this.wiseMethod = {
        wiseEmail: '',
        accountHolder: '',
        routingNumber: '',
        accountNumber: '',
        accountType: null,
        address: '',
      }
    },

    onHidden() {
      this.resetForm()
      this.$emit('update:show-side-bar', false)
    },

    handleMaxAmount() {
      const { balance } = this.user?.data
      this.requestAmount = this.amountFormat(balance)
    },

    handleChangeRequestAmount() {
      const amount = this.user?.data?.balance

      if (this.requestAmount > amount) {
        this.requestAmount = this.amountFormat(amount)
      }
      if (String(this.requestAmount).indexOf('.') === -1) { return }
      if ((this.requestAmount.length - String(this.requestAmount).indexOf('.')) > 2) {
        this.requestAmount = parseFloat(this.requestAmount).toFixed(2)
      }
    },

    async handleActionNext() {
      const success = await this.$refs.refFormObserver.validate()
      if (success) {
        if (this.refundReason.value === 'permanent_suspended') {
          const { content } = this.listAccount
          const itemAmountAds = content.find(element => element.balance >= 1)

          if (!_isEmpty(itemAmountAds)) {
            this.$refs['modal-warning-amount-ads-account'].showModalWarningAmount()
          } else {
            this.submitInfoRequestPayment()
          }
        } else {
          this.submitInfoRequestPayment()
        }
      }
    },

    submitInfoRequestPayment() {
      const params = {
        amount: Number(this.requestAmount),
        reasonType: this.refundReason.value,
        paymentMethod: this.receivingMethod.value,
        ...(this.detailReason && { description: this.detailReason }),
        ...(this.receivingMethod?.value !== 'creditCard' && {
          additionalInfo: {
            ...(this.emailPaymentMethod && { email: this.emailPaymentMethod }),
            ...(this.USDTMethod.walletAddress && { usdtWalletAddress: this.USDTMethod.walletAddress }),
            ...(this.USDTMethod.USDTNetwork && { usdtNetwork: this.USDTMethod.USDTNetwork.value }),
            ...(this.wiseMethod.wiseEmail && { email: this.wiseMethod.wiseEmail }),
            ...(this.wiseMethod.accountHolder && { wiseAccountHolder: this.wiseMethod.accountHolder }),
            ...(this.wiseMethod.routingNumber && { wiseRountingNumber: this.wiseMethod.routingNumber }),
            ...(this.wiseMethod.accountNumber && { wiseAccountNumber: this.wiseMethod.accountNumber }),
            ...(this.wiseMethod.accountType && { wiseAccountType: this.wiseMethod.accountType.value }),
            ...(this.wiseMethod.address && { wiseAddress: this.wiseMethod.address }),
          },
        }),
      }
      this.$emit('handelNextRequest', params)
    },

    handleSelectReceivingMethod() {
      if (this.$refs.refFormObserver?.refs?.email) {
        this.$refs.refFormObserver.refs.email.reset()
      }

      this.emailPaymentMethod = ''
      this.USDTMethod = {
        walletAddress: '',
        USDTNetwork: '',
      }
      this.wiseMethod = {
        wiseEmail: '',
        accountHolder: '',
        routingNumber: '',
        accountNumber: '',
        accountType: '',
        address: '',
      }
    },
  },
}
</script>

<style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";

    #sidebar-form-request-refund {
      border-radius: 20px 0px 0px 20px;

      .b-sidebar-body {
        padding: 40px;
        overflow-y: hidden;

        @media (max-width: 575.98px) {
          padding: 30px;
        }
      }

      @media (max-width: 575.98px) {
        width: 450px;
      }
    }

    .input-height {
      .vs__dropdown-toggle {
        padding: 0.438rem 1rem !important;

        .vs__actions {
          padding: 0;

          .vs__open-indicator {
            margin: 0;
          }
        }

        .vs__selected,
        .vs__search,
        .vs__selected-options {
          margin: 0;
          padding: 0;

          .vs__selected {
            margin: 0;
          }
        }
      }

      .vs__dropdown-menu {
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 4px 4px 25px 4px rgba(0, 0, 0, 0.05);
      }
    }
</style>

<style lang="scss" scoped>
.header-popup {
  margin-bottom: 30px;

  .title-popup {
    font-size: 24px;
    margin-bottom: 4px;
  }

  .description-form-request-refund {
    font-size: 14px;
    margin: 0;
  }
}

.ic-warning {
  margin-right: 12px;
}

.content-warning {
  font-style: italic;
}

.popup-auto-refund-form {
  height: calc(100% - 220px);
  overflow-y: auto;

  @media screen and (max-width: 576px) {
    height: calc(100% - 267px);
  }
}

.group-input {
  margin-bottom: 24px;
}

.input-height {
  height: 48px;
}

.btn-max-amount {
  cursor: pointer;
  background: linear-gradient(128deg, #81FFF4 -29.89%, #6E44FF 98.77%);
  background-clip: text !important;
  color: transparent;
}
</style>
