var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-form-request-refund","sidebar-class":"sidebar-lg","bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.onHidden},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[[_c('div',{staticClass:"header-popup"},[_c('h3',{staticClass:"title-popup"},[_vm._v(" "+_vm._s(_vm.$t('payment.autoRefundFeature.titleRefundRequestForm'))+" ")]),_c('p',{staticClass:"description-form-request-refund"},[_vm._v(" "+_vm._s(_vm.$t('payment.autoRefundFeature.subPopupAutoRefund'))+" ")])]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"popup-auto-refund-form",on:{"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-form-group',{staticClass:"group-input"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"requestAmount"}},[_vm._v(" "+_vm._s(_vm.$t('payment.autoRefundFeature.txtRequestedAmount'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"request-amount-input","name":_vm.$t('payment.autoRefundFeature.txtRequestedAmount'),"rules":Object.assign({}, {required: _vm.required,
                greaterThanZero: _vm.greaterThanZero},
                ( _vm.receivingMethod && _vm.receivingMethod.value === 'payoneer' && {minAmount: { min: _vm.minTopUp }}))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"prepend":"$"}},[_c('b-form-input',{staticClass:"input-height",attrs:{"id":"request-amount","data-testid":"request-amount","name":_vm.$t('payment.autoRefundFeature.txtRequestedAmount'),"placeholder":_vm.$t('payment.autoRefundFeature.placeholderRequestedAmount'),"state":errors.length > 0 ? false : null},on:{"keyup":_vm.handleChangeRequestAmount,"keydown":_vm.numberDecimal},model:{value:(_vm.requestAmount),callback:function ($$v) {_vm.requestAmount=$$v},expression:"requestAmount"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('span',{staticClass:"btn-max-amount",on:{"click":_vm.handleMaxAmount}},[_vm._v(" "+_vm._s(_vm.$t('payment.autoRefundFeature.txtMax'))+" ")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"group-input"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"refundReason"}},[_vm._v(" "+_vm._s(_vm.$t('payment.autoRefundFeature.txtRefundReason'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"refund-reason-input","name":_vm.$t('payment.autoRefundFeature.txtRefundReason'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{staticClass:"input-height",attrs:{"input-id":"refund-reason","data-testid":"refund-reason","clearable":false,"name":_vm.$t('payment.autoRefundFeature.txtRefundReason'),"placeholder":_vm.$t('payment.autoRefundFeature.txtPlaceholderRefundReason'),"options":_vm.refundReasonOptions,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
              var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"13"}})],1)]}}],null,true),model:{value:(_vm.refundReason),callback:function ($$v) {_vm.refundReason=$$v},expression:"refundReason"}}),(_vm.refundReason && _vm.refundReason.value === 'permanent_suspended')?_c('div',{staticClass:"text-warning d-flex align-items-center"},[_c('div',{staticClass:"ic-warning"},[_c('feather-icon',{attrs:{"icon":"AlertTriangleIcon","size":"16"}})],1),_c('p',{staticClass:"font-12 m-0 content-warning"},[_vm._v(" "+_vm._s(_vm.$t('payment.autoRefundFeature.txtWarningPermanentServiceDiscontinuation'))+" ")])]):(_vm.refundReason && (_vm.refundReason.value === 'temporary_suspended' || _vm.refundReason.value === 'other'))?_c('div',{staticClass:"text-warning d-flex align-items-center"},[_c('div',{staticClass:"ic-warning"},[_c('feather-icon',{attrs:{"icon":"AlertTriangleIcon","size":"16"}})],1),_c('p',{staticClass:"font-12 m-0 content-warning"},[_vm._v(" "+_vm._s(_vm.$t('payment.autoRefundFeature.noteReasonRefundIsTemporary'))+" ")])]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"group-input"},[_c('validation-provider',{attrs:{"name":_vm.$t('payment.autoRefundFeature.nameDetailReason'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('payment.autoRefundFeature.nameDetailReason'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t('payment.autoRefundFeature.placeholderDetailReason'),"rows":"3"},model:{value:(_vm.detailReason),callback:function ($$v) {_vm.detailReason=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"detailReason"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"group-input"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"ReceivingMethod"}},[_vm._v(" "+_vm._s(_vm.$t('payment.autoRefundFeature.txtReceivingMethod'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"receiving-method-input","name":_vm.$t('payment.autoRefundFeature.txtReceivingMethod'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{staticClass:"input-height",attrs:{"input-id":"receiving-method","data-testid":"receiving-method","clearable":false,"name":_vm.$t('payment.autoRefundFeature.txtReceivingMethod'),"placeholder":_vm.$t('payment.autoRefundFeature.txtPlaceholderReceivingMethod'),"options":_vm.receivingMethodOptions,"state":errors.length > 0 ? false : null},on:{"input":_vm.handleSelectReceivingMethod},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
              var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"13"}})],1)]}}],null,true),model:{value:(_vm.receivingMethod),callback:function ($$v) {_vm.receivingMethod=$$v},expression:"receivingMethod"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.receivingMethodRequest)?_c('b-form-group',{staticClass:"group-input"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"email"}},[_vm._v(" "+_vm._s(_vm.methodSelected)+" email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"method-email-input","name":"email","rules":_vm.receivingMethodRequest ? 'required|email' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-height",attrs:{"id":"method-email","data-testid":"method-email","name":"email","placeholder":_vm.$t('payment.autoRefundFeature.placeholderMethodEmail', {methodSelected: _vm.methodSelected}),"state":errors.length > 0 ? false : null},model:{value:(_vm.emailPaymentMethod),callback:function ($$v) {_vm.emailPaymentMethod=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"emailPaymentMethod"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.receivingMethod && _vm.receivingMethod.value === 'USDT')?_c('b-form-group',{staticClass:"group-input"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"ReceivingMethod"}},[_vm._v(" "+_vm._s(_vm.$t('payment.USDT.labelWalletAddress', { value: 'USDT' }))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"wallet-address-input","name":_vm.$t('payment.USDT.labelWalletAddress', { value: 'USDT' }),"rules":_vm.receivingMethod && _vm.receivingMethod.value === 'USDT' ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-height",attrs:{"id":"wallet-address","data-testid":"wallet-address","name":_vm.$t('payment.USDT.labelWalletAddress', { value: 'USDT' }),"placeholder":_vm.$t('payment.autoRefundFeature.placeholderWalletAddress'),"state":errors.length > 0 ? false : null},model:{value:(_vm.USDTMethod.walletAddress),callback:function ($$v) {_vm.$set(_vm.USDTMethod, "walletAddress", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"USDTMethod.walletAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.receivingMethod && _vm.receivingMethod.value === 'USDT')?_c('b-form-group',{staticClass:"group-input"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"ReceivingMethod"}},[_vm._v(" "+_vm._s(_vm.$t('payment.autoRefundFeature.labelUSDTNetwork'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"usdt-network-input","name":_vm.$t('payment.autoRefundFeature.labelUSDTNetwork'),"rules":_vm.receivingMethod && _vm.receivingMethod.value === 'USDT' ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{staticClass:"input-height",attrs:{"input-id":"receiving-method","data-testid":"receiving-method","placeholder":_vm.$t('payment.autoRefundFeature.placeholderUSDTNetwork'),"clearable":false,"name":_vm.$t('payment.autoRefundFeature.labelUSDTNetwork'),"options":_vm.usdtNetworkOptions,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
              var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"13"}})],1)]}}],null,true),model:{value:(_vm.USDTMethod.USDTNetwork),callback:function ($$v) {_vm.$set(_vm.USDTMethod, "USDTNetwork", $$v)},expression:"USDTMethod.USDTNetwork"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.receivingMethod && _vm.receivingMethod.value === 'transferWise')?_c('b-form-group',{staticClass:"group-input"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"ReceivingMethod"}},[_vm._v(" Wise email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"receiving-method-input","name":"Wise email","rules":_vm.receivingMethod && _vm.receivingMethod.value === 'transferWise' ? 'required|email' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-height",attrs:{"id":"request-amount","data-testid":"request-amount","name":"Wise email","placeholder":_vm.$t('payment.autoRefundFeature.placeholderWiseEmail'),"state":errors.length > 0 ? false : null},model:{value:(_vm.wiseMethod.wiseEmail),callback:function ($$v) {_vm.$set(_vm.wiseMethod, "wiseEmail", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"wiseMethod.wiseEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.receivingMethod && _vm.receivingMethod.value === 'transferWise')?_c('b-form-group',{staticClass:"group-input"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"ReceivingMethod"}},[_vm._v(" "+_vm._s(_vm.$t('payment.wiseTransfer.titleAccountHolder', { paymentMethod: 'Wise'}))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"wise-email-input","name":_vm.$t('payment.wiseTransfer.titleAccountHolder', { paymentMethod: 'Wise'}),"rules":_vm.receivingMethod && _vm.receivingMethod.value === 'transferWise' ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-height",attrs:{"id":"wise-email","data-testid":"wise-email","name":_vm.$t('payment.wiseTransfer.titleAccountHolder', { paymentMethod: 'Wise'}),"placeholder":_vm.$t('payment.autoRefundFeature.placeholderAccountHolder'),"state":errors.length > 0 ? false : null},model:{value:(_vm.wiseMethod.accountHolder),callback:function ($$v) {_vm.$set(_vm.wiseMethod, "accountHolder", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"wiseMethod.accountHolder"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.receivingMethod && _vm.receivingMethod.value === 'transferWise')?_c('b-form-group',{staticClass:"group-input"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"ReceivingMethod"}},[_vm._v(" "+_vm._s(_vm.$t('payment.wiseTransfer.titleRoutingNumber', {paymentMethod: 'Wise'}))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"routing-number-input","name":_vm.$t('payment.wiseTransfer.titleRoutingNumber', {paymentMethod: 'Wise'}),"rules":_vm.receivingMethod && _vm.receivingMethod.value === 'transferWise' ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-height",attrs:{"id":"routing-number","data-testid":"routing-number","name":_vm.$t('payment.wiseTransfer.titleRoutingNumber', {paymentMethod: 'Wise'}),"placeholder":_vm.$t('payment.autoRefundFeature.placeholderRoutingNumber'),"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.wiseMethod.routingNumber),callback:function ($$v) {_vm.$set(_vm.wiseMethod, "routingNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"wiseMethod.routingNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.receivingMethod && _vm.receivingMethod.value === 'transferWise')?_c('b-form-group',{staticClass:"group-input"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"ReceivingMethod"}},[_vm._v(" "+_vm._s(_vm.$t('payment.wiseTransfer.textBankAccountNumber', {paymentMethod: 'Wise'}))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"account-number-input","name":_vm.$t('payment.wiseTransfer.textBankAccountNumber', {paymentMethod: 'Wise'}),"rules":_vm.receivingMethod && _vm.receivingMethod.value === 'transferWise' ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-height",attrs:{"id":"account-number","data-testid":"account-number","name":_vm.$t('payment.wiseTransfer.textBankAccountNumber', {paymentMethod: 'Wise'}),"placeholder":_vm.$t('payment.autoRefundFeature.placeholderAccountNumber'),"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.wiseMethod.accountNumber),callback:function ($$v) {_vm.$set(_vm.wiseMethod, "accountNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"wiseMethod.accountNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.receivingMethod && _vm.receivingMethod.value === 'transferWise')?_c('b-form-group',{staticClass:"group-input"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"ReceivingMethod"}},[_vm._v(" "+_vm._s(_vm.$t('payment.wiseTransfer.titleAccountType', {paymentMethod: 'Wise'}))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"account-type-input","name":_vm.$t('payment.wiseTransfer.titleAccountType'),"rules":_vm.receivingMethod && _vm.receivingMethod.value === 'transferWise' ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{staticClass:"input-height",attrs:{"input-id":"account-type","data-testid":"account-type","placeholder":_vm.$t('payment.autoRefundFeature.placeholderAccountType'),"clearable":false,"name":_vm.$t('payment.wiseTransfer.titleAccountType'),"options":_vm.accountTypeOptions,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
              var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"13"}})],1)]}}],null,true),model:{value:(_vm.wiseMethod.accountType),callback:function ($$v) {_vm.$set(_vm.wiseMethod, "accountType", $$v)},expression:"wiseMethod.accountType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.receivingMethod && _vm.receivingMethod.value === 'transferWise')?_c('b-form-group',{staticClass:"group-input"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"ReceivingMethod"}},[_vm._v(" "+_vm._s(_vm.$t('payment.wiseTransfer.textAddress', { paymentMethod: 'Wise' }))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"address-input","name":_vm.$t('referral.tabPartnerProgram.textAddress'),"rules":_vm.receivingMethod && _vm.receivingMethod.value === 'transferWise' ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-height",attrs:{"id":"address","data-testid":"address","name":_vm.$t('referral.tabPartnerProgram.textAddress'),"placeholder":_vm.$t('payment.autoRefundFeature.placeholderAddress'),"state":errors.length > 0 ? false : null},model:{value:(_vm.wiseMethod.address),callback:function ($$v) {_vm.$set(_vm.wiseMethod, "address", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"wiseMethod.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1),_c('div',{staticClass:"footer-popup"},[_c('div',{staticClass:"checkbox"},[_c('b-form-checkbox',{staticClass:"custom-control-primary",on:{"click":function($event){!_vm.selected}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._v(" "+_vm._s(_vm.$t('register.textAcceptVerify'))+" "),_c('a',{attrs:{"target":"_blank","href":_vm.termsOfService}},[_vm._v(_vm._s(_vm.$t('register.textTermsOfService')))])])],1),_c('div',{staticClass:"d-flex justify-content-center mt-2"},[_c('btn-loading',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant-convert":"btn-submit-outline","pill":"","loading":_vm.loading},on:{"click":_vm.onHidden}},[_vm._v(" "+_vm._s(_vm.$t('common.btnCancel'))+" ")]),_c('btn-loading',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant-convert":"btn-submit","loading":_vm.loading,"disabled":invalid || !_vm.selected},on:{"click":_vm.handleActionNext}},[_vm._v(" "+_vm._s(_vm.$t('payment.autoRefundFeature.btnNext'))+" ")])],1)])]}}])})]],2),_c('modal-warning-amount-ads-account',{ref:"modal-warning-amount-ads-account",on:{"submitInfoRequestPayment":_vm.submitInfoRequestPayment,"onHidden":_vm.onHidden}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }