import { render, staticRenderFns } from "./ConnectTikTokSuccessModal.vue?vue&type=template&id=620cdb00&scoped=true&"
import script from "./ConnectTikTokSuccessModal.vue?vue&type=script&lang=js&"
export * from "./ConnectTikTokSuccessModal.vue?vue&type=script&lang=js&"
import style0 from "./ConnectTikTokSuccessModal.vue?vue&type=style&index=0&id=620cdb00&scoped=true&lang=scss&"
import style1 from "./ConnectTikTokSuccessModal.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620cdb00",
  null
  
)

export default component.exports