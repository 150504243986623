<template>
  <b-modal
    ref="modal-submit-request-refund"
    id="modal-confirm-request"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between flex-column top-modal">
      <h3 class="title-popup">
        {{ $t('payment.autoRefundFeature.titleRefundRequestForm') }}
      </h3>
      <!-- <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div> -->
      <p class="description-form-request-refund">
        {{ $t('payment.autoRefundFeature.txtHereIsASummary') }} <span class="text-notice">{{ $t('payment.autoRefundFeature.txtWeWillProcess') }}</span> {{ $t('payment.autoRefundFeature.txtItWillThenTake') }} <span class="text-notice">{{ $t('payment.autoRefundFeature.txtForTheRefund') }}</span>
      </p>
    </div>

    <!-- body content -->
    <div class="body-content-modal">
      <div class="info-request">
        <p class="title-info">
          {{ $t('payment.autoRefundFeature.txtRequestedAmount') }}
        </p>
        <p class="content-info">
          ${{ requestedAmount }}
        </p>
      </div>

      <div class="info-request">
        <p class="title-info">
          {{ $t('payment.autoRefundFeature.txtRefundReason') }}
        </p>
        <p class="content-info">
          {{ refundReason }}
        </p>
      </div>

      <div class="info-request">
        <p class="title-info">
          {{ $t('payment.autoRefundFeature.nameDetailReason') }}
        </p>
        <p class="content-description">
          {{ detailReason }}
        </p>
      </div>

      <div class="info-request">
        <p class="title-info">
          {{ $t('payment.autoRefundFeature.txtReceivingMethod') }}
        </p>
        <p class="content-info">
          {{ receivingMethod }}
        </p>
      </div>

      <div
        v-if="mailReceivingMethod"
        class="info-request"
      >
        <p class="title-info">
          {{ receivingMethod }} email
        </p>
        <p class="content-info">
          {{ mailReceivingMethod }}
        </p>
      </div>

      <div
        v-if="walletAddress"
        class="info-request"
      >
        <p class="title-info">
          {{ $t('payment.USDT.labelWalletAddress', { value: 'USDT' }) }}
        </p>
        <p class="content-info">
          {{ walletAddress }}
        </p>
      </div>

      <div
        v-if="usdtNetwork"
        class="info-request"
      >
        <p class="title-info">
          {{ $t('payment.autoRefundFeature.labelUSDTNetwork') }}
        </p>
        <p class="content-info">
          {{ usdtNetwork }}
        </p>
      </div>

      <div
        v-if="wiseAccountHolder"
        class="info-request"
      >
        <p class="title-info">
          {{ $t('payment.wiseTransfer.titleAccountHolder', { paymentMethod: 'Wise'}) }}
        </p>
        <p class="content-info">
          {{ wiseAccountHolder }}
        </p>
      </div>

      <div
        v-if="wiseRountingNumber"
        class="info-request"
      >
        <p class="title-info">
          {{ $t('payment.wiseTransfer.titleRoutingNumber', { paymentMethod: 'Wise'}) }}
        </p>
        <p class="content-info">
          {{ wiseRountingNumber }}
        </p>
      </div>

      <div
        v-if="wiseAccountNumber"
        class="info-request"
      >
        <p class="title-info">
          {{ $t('payment.wiseTransfer.textBankAccountNumber', {paymentMethod: 'Wise'}) }}
        </p>
        <p class="content-info">
          {{ wiseAccountNumber }}
        </p>
      </div>

      <div
        v-if="wiseAccountType"
        class="info-request"
      >
        <p class="title-info">
          {{ $t('payment.wiseTransfer.titleAccountType', {paymentMethod: 'Wise'}) }}
        </p>
        <p class="content-info">
          {{ wiseAccountType }}
        </p>
      </div>

      <div
        v-if="wiseAddress"
        class="info-request"
      >
        <p class="title-info">
          {{ $t('payment.wiseTransfer.textAddress',{ paymentMethod: 'Wise' }) }}
        </p>
        <p class="content-info">
          {{ wiseAddress }}
        </p>
      </div>

    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <btn-loading
        variant-convert="btn-submit-outline"
        :disabled="loading"
        pill
        @click="hideModal"
      >
        {{ $t('autoTopUp.setupAutomation.edit') }}
      </btn-loading>

      <btn-loading
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant-convert="btn-submit"
        class="ml-1 btn-submit-request"
        :loading="loading"
        @click="handleSubmitRequest"
      >
        {{ $t('forgotPW.btnSendLinkReset') }}
      </btn-loading>
    </div>
    <!--/modal footer-->

  </b-modal>
</template>
<script>
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */

import {
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { toastification } from '@core/mixins/toast'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers } from 'vuex'
import { TRANSACTION_TYPE } from '@/constants'
import stepGuideMixin from '@/mixins/stepGuideMixin'

const { mapGetters, mapActions } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BModal,
    BtnLoading,
  },

  directives: {
    Ripple,
  },

  mixins: [toastification, stepGuideMixin],

  props: {
    infoRequestRefund: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {

    }
  },

  computed: {
    ...mapGetters(['loading', 'status', 'message']),
    ...mapGettersAuth(['user']),

    requestedAmount() {
      return this.infoRequestRefund?.amount
    },

    refundReason() {
      switch (this.infoRequestRefund?.reasonType) {
        case 'permanent_suspended':
          return this.$t('payment.autoRefundFeature.txtPermanentServiceDiscontinuation')
        case 'temporary_suspended':
          return this.$t('payment.autoRefundFeature.txtTemporarilyStopUsingService')
        case 'other':
          return this.$t('payment.autoRefundFeature.txtOther')
        default:
          return this.infoRequestRefund?.reasonType
      }
    },

    receivingMethod() {
      switch (this.infoRequestRefund?.paymentMethod) {
        case 'paypal':
          return 'PayPal'
        case 'creditCard':
          return 'Credit card'
        case 'payoneer':
          return 'Payoneer'
        case 'airWallex':
          return 'Airwallex'
        case 'transferWise':
          return 'Wise'
        case 'tazapay':
          return 'Tazapay'
        default:
          return this.infoRequestRefund?.paymentMethod
      }
    },

    mailReceivingMethod() {
      return this.infoRequestRefund?.additionalInfo?.email
    },

    walletAddress() {
      return this.infoRequestRefund?.additionalInfo?.usdtWalletAddress
    },

    usdtNetwork() {
      return this.infoRequestRefund?.additionalInfo?.usdtNetwork
    },

    wiseAccountHolder() {
      return this.infoRequestRefund?.additionalInfo?.wiseAccountHolder
    },

    wiseRountingNumber() {
      return this.infoRequestRefund?.additionalInfo?.wiseRountingNumber
    },

    wiseAccountNumber() {
      return this.infoRequestRefund?.additionalInfo?.wiseAccountNumber
    },

    wiseAccountType() {
      return this.infoRequestRefund?.additionalInfo?.wiseAccountType
    },

    wiseAddress() {
      return this.infoRequestRefund?.additionalInfo?.wiseAddress
    },

    detailReason() {
      return this.infoRequestRefund?.description
    },
  },

  methods: {
    ...mapActions(['requestAutoRefund', 'getHistory']),
    ...mapActionsAuth(['getAccountInfo']),

    hideModal() {
      this.$refs['modal-submit-request-refund'].hide()
    },

    showModal() {
      this.$refs['modal-submit-request-refund'].show()
    },

    async handleSubmitRequest() {
      await this.requestAutoRefund(this.infoRequestRefund)

      if (this.status) {
        const params = {
          page: 1,
          size: 10,
          types: [TRANSACTION_TYPE.REFUND_USER_BALANCE],
        }
        await this.getHistory({
          type: 'refund_user_balance',
          params,
        })
        await this.getAccountInfo()
        this.toastSuccess(this.$t('payment.autoRefundFeature.requestRefundSuccess'))
        this.$router.push(this.hasStepGuide ? '/payment/add-fund?tabActive=refund_history' : '/payment?tab=refund_history')
        this.hideModal()
        this.$emit('submitRequestRefundSuccess')
      } else {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>

<style lang="scss">
  #modal-confirm-request {

    .modal-body {
      padding: 40px;
    }
  }
</style>

<style lang="scss" scoped>
  .top-modal {
    margin-bottom: 10px;
  }

  .title-popup {
      font-size: 24px;
      margin-bottom: 4px;
    }

  .description-form-request-refund {
    color: rgba(0, 0, 0, 0.70);
    font-size: 14px;
    margin: 0;
    border-radius: 10px;
    background:  rgba(38, 103, 255, 0.10);
    padding: 6px;

    .text-notice {
      color:  #000;
      font-weight: bold;
    }
  }

  .body-content-modal {
    border-radius: 20px;
    background:  rgba(0, 0, 0, 0.05);
    padding: 16px;

    .info-request {
      margin-bottom: 12px;
      word-wrap: break-word;

      .title-info {
        font-size: 12px;
        font-weight: 500;
        margin: 0;
      }

      .content-info {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
      }
    }
  }

  .content-description {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
  }

  .btn-submit-request {
    z-index: 999;
  }
</style>
