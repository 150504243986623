<template>
  <div>
    <banner-header
      v-if="isExclusivePartner"
      :img="require('@/assets/images/banner/banner-popup-adreach.png')"
    >
      <template #content>
        <div
          class="btn-join-partnero"
        >
          <div class="content-background">
            <h1 class="title-ecomdy-affiliate">
              <span class="text-opacity">Easy and automatic</span> Top-up
            </h1>
            <h1 class="title-ecomdy-affiliate">
              in Pakistan Rupees <span class="text-opacity">now with Payfast!</span>
            </h1>
          </div>
          <div class="btn-controller">
            <div class="btn-learn-more">
              <a
                href="https://ecomdycom.larksuite.com/docx/MeOvdQghRo48tYxmCT3ulHOysmh"
                target="_blank"
              >
                {{ $t('referral.btnLearnMore') }}
              </a>
            </div>
          </div>
        </div>
      </template>
    </banner-header>

    <div
      class="payment"
    >
      <div class="content-balance mb-2">
        <!--Balance overview-->
        <balance-overview />
        <!--/ Balance overview-->
      </div>

      <div class="tabs-history">
        <!--History-->
        <tabs-history />
        <!--/ History-->
      </div>
    </div>

    <banner-kickstart-modal
      :show="showModalKickstart"
      :img="require('@/assets/images/pages/payment/banner-top-up-payfast.png')"
      link="https://ecomdycom.larksuite.com/docx/MeOvdQghRo48tYxmCT3ulHOysmh"
      @close-modal="onCloseModalKickstart"
    />

    <ConnectTikTokSuccessModal v-if="isPancakeUser" />
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import envMixin from '@/mixins/envMixin'
import BannerHeader from '@/views/home/components/BannerHeader.vue'
import BannerKickstartModal from '@/views/home/components/BannerKickstartModal.vue'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import ConnectTikTokSuccessModal from '@/views/payment/components/ConnectTikTokSuccessModal.vue'
import authMixin from '@/mixins/authMixin'
import BalanceOverview from './components/BalanceOverview.vue'
import TabsHistory from './components/TabsHistory/TabsHistory.vue'

const { mapGetters } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    ConnectTikTokSuccessModal,
    TabsHistory,
    BalanceOverview,
    // components
    BannerHeader,
    BannerKickstartModal,
  },
  mixins: [envMixin, stepGuideMixin, authMixin],

  data() {
    return {
      // showBalanceAllowCation: false,
      showModalKickstart: false,
    }
  },

  computed: {
    ...mapGetters(['status', 'listBusinessAccountHistoryLimit', 'loading']),
    ...mapGettersAuth(['user']),
  },

  watch: {
    //   $route: {
    //     immediate: true,
    //     handler(val) {
    //       if (val.query?.type === 'show_balance') {
    //         this.showBalance()
    //       }
    //     },

    user: {
      handler() {
        if (localStorage.getItem('show-banner-modal') !== 'hide' && !this.hasStepGuide && this.isExclusivePartner) {
          this.showModalKickstart = true
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    // showBalance() {
    //   this.showBalanceAllowCation = true
    // },

    onCloseModalKickstart() {
      this.showModalKickstart = false
      localStorage.setItem('show-banner-modal', 'hide')
    },
  },
}
</script>
